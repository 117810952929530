import {
  ArrowDropDownOutlined,
  CheckBoxOutlined,
  GestureOutlined,
  PhotoOutlined,
  RadioButtonCheckedOutlined,
  StarOutlineOutlined,
  TextFieldsOutlined,
  ToggleOnOutlined
} from '@mui/icons-material';
import {
  Button,
  ButtonGroup,
  ClickAwayListener,
  Grow,
  ListItemIcon,
  MenuItem,
  MenuList,
  Paper,
  Popper
} from '@mui/material';
import { uniqueId } from 'lodash';
import PropTypes from 'prop-types';
import { useMemo, useRef, useState } from 'react';
import theme from 'src/theme';
import getQuestionDefaultProps from './QuestionsTypes/getQuestionDefaultProps';

const options = [
  {
    label: 'בחירה מרובה',
    icon: <RadioButtonCheckedOutlined color="error" />,
    type: 'radiogroup',
    validFor: ['evaluations', 'knowledge_tests']
  },
  {
    label: 'תיבת סימון',
    icon: <CheckBoxOutlined color="info" />,
    type: 'checkbox',
    validFor: ['evaluations', 'knowledge_tests']
  },
  {
    label: 'טקסט',
    icon: <TextFieldsOutlined color="primary" />,
    type: 'text',
    validFor: ['evaluations', 'knowledge_tests']
  },
  {
    label: 'דירוג',
    icon: <StarOutlineOutlined color="warning" />,
    type: 'rating',
    validFor: ['evaluations']
  },
  {
    label: 'כן/לא',
    icon: <ToggleOnOutlined color="success" />,
    type: 'boolean',
    validFor: ['evaluations', 'knowledge_tests']
  },
  {
    label: 'חתימה',
    icon: <GestureOutlined color="secondary" />,
    type: 'signaturepad',
    validFor: ['evaluations']
  },
  {
    label: 'תמונה',
    icon: <PhotoOutlined color="secondary" />,
    type: 'image',
    validFor: ['knowledge_tests']
  }
];

const AddQuestionButtonGroup = ({
  setEvalValues,
  evalProcessValues,
  currentPage,
  currentQuestion,
  componentName = 'evaluation'
}) => {
  AddQuestionButtonGroup.propTypes = {
    setEvalValues: PropTypes.func,
    evalProcessValues: PropTypes.object,
    currentPage: PropTypes.number,
    currentQuestion: PropTypes.number,
    componentName: PropTypes.string
  };
  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);

  const handleAddNewQuestion = (questionType = 'radiogroup') => {
    const temp = { ...evalProcessValues };
    if (temp.pages && temp.pages[currentPage].elements) {
      temp.pages[currentPage].elements.push({
        ...getQuestionDefaultProps(questionType, currentQuestion, currentPage)
      });

      setEvalValues(temp);
    } else {
      if (!temp || !temp.pages) {
        setEvalValues({
          ...evalProcessValues,
          pages: [
            {
              name: 'עמוד 1',
              elements: [
                getQuestionDefaultProps(
                  questionType,
                  currentQuestion,
                  currentPage
                )
              ],
              order: 0
            }
          ]
        });
      }
    }
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  const validOptions = useMemo(
    () => options.filter((el) => el.validFor.includes(componentName)),
    [componentName]
  );

  return (
    <>
      <ButtonGroup
        variant="outlined"
        ref={anchorRef}
        aria-label="Button group with a nested menu"
        sx={{ mb: 2 }}
      >
        <Button onClick={() => handleAddNewQuestion()}>הוספת שאלה</Button>
        <Button
          size="small"
          aria-controls={open ? 'split-button-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-label="select merge strategy"
          aria-haspopup="menu"
          onClick={handleToggle}
        >
          <ArrowDropDownOutlined />
        </Button>
      </ButtonGroup>
      <Popper
        sx={{
          zIndex: theme.zIndex.modal + 1
        }}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        // disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'bottom' ? 'center top' : 'center bottom'
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="split-button-menu" autoFocusItem>
                  {validOptions.map((option, index) => (
                    <MenuItem
                      key={`${uniqueId('list-item')}-${option.name}`}
                      onClick={() => {
                        // console.log(option);

                        handleAddNewQuestion(validOptions[index].type);
                      }}
                      sx={{ minWidth: 120 }}
                    >
                      <ListItemIcon>{option.icon}</ListItemIcon>
                      {/* <ListItemText primary={option.label} /> */}
                      {option.label}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
};

export default AddQuestionButtonGroup;
