import { uniqueId } from 'lodash';
import { any } from 'prop-types';
import { useEffect, useState } from 'react';
import { Survey } from 'survey-react-ui';
import './answersPreview.css';

const getQuestionCorrectAnswer = (surveyJSON, questionName) => {
  const [question, page] = questionName.split('-');
  const [_, questionIndex] = question.split('question');
  const [__, pageIndex] = page.split('page');
  return surveyJSON.pages[pageIndex].elements[questionIndex].correctAnswer;
};

const evaluateAnswer = (question, userCorrectAnswers, questionValue) => {
  const tempAllVals = Object.values(question.getAllValues());

  let qValue;
  if (questionValue) {
    qValue = questionValue;
  }
  if (tempAllVals && tempAllVals.length > 0) {
    const questionVals = tempAllVals.find((el) => el.name === question.name);
    if (questionVals && questionVals.value) {
      qValue = questionVals.value;
    }
  }

  const correctAnswer = userCorrectAnswers || question.correctAnswer;
  if (correctAnswer) {
    if (Array.isArray(correctAnswer)) {
      const correctCount = correctAnswer.length;

      if (Array.isArray(qValue)) {
        // Calculate the proportion of correct answers provided
        const correctAnswersScore = qValue.reduce((acc, curr) => {
          if (correctAnswer.includes(curr)) {
            const weight = 1 / correctCount; // Each correct answer contributes equally
            return acc + weight;
          }
          return acc;
        }, 0);
        return Math.min(correctAnswersScore, 1); // Ensure the score does not exceed 1
      }
      if (question.getType() === 'radiogroup') {
        return correctAnswer.includes(qValue) ? 1 : 0;
      }
      // Single value provided for a multi-answer question
      return correctAnswer.includes(qValue) ? 1 / correctCount : 0;
    }
    // Single correct answer
    return qValue === correctAnswer ? 1 : 0;
  }

  // Handle rating questions
  if (question.getType() === 'rating') {
    const normalizedValue =
      (qValue - question.rateMin) / (question.rateMax - question.rateMin);
    return normalizedValue; // Value between 0 and 1
  }

  return 0; // Default to 0 (false) if no conditions match
};

const ShowCorrectAndIncorrectSurveyAnswers = ({
  survey,
  dataAnswers,
  surveyJSON
}) => {
  ShowCorrectAndIncorrectSurveyAnswers.propTypes = {
    survey: any,
    surveyJSON: any,
    dataAnswers: any
  };
  const [key, setKey] = useState(uniqueId());

  useEffect(() => {
    if (dataAnswers) {
      survey.data = dataAnswers;
      survey.showNavigationButtons = 'none';
      survey.showProgressBar = 'off';
      survey.showTimerPanel = 'none';
      survey.maxTimeToFinishPage = 0;
      survey.maxTimeToFinish = 0;
      //   console.log(surveyJSON);

      survey.onAfterRenderQuestion.add((_, options) => {
        const question = survey.getQuestionByName(
          options.question.jsonObj.name
        );
        // const qValue = dataAnswers?.[options.question.jsonObj.name] || [];
        const userCorrectAnswers = getQuestionCorrectAnswer(
          surveyJSON,
          options.question.jsonObj.name
        );

        const tempAllVals = Object.values(question.getAllValues());

        let qValue;
        if (dataAnswers && dataAnswers?.[options.question.jsonObj.name]) {
          qValue = dataAnswers?.[options.question.jsonObj.name];
        }
        if (tempAllVals && tempAllVals.length > 0) {
          const questionVals = tempAllVals.find(
            (el) => el.name === question.name
          );
          if (questionVals && questionVals.value) {
            qValue = questionVals.value;
          }
        }

        const score = evaluateAnswer(
          question,
          userCorrectAnswers,
          qValue || []
        ); // Calculate the score

        const header = options.htmlElement.querySelector('h5');
        const span = document.createElement('span');

        // Set feedback based on score
        if (score === 1) {
          header.style.background =
            'linear-gradient(to left, #00800061 50%, rgba(0,0,0,0)';
          span.innerHTML = 'נכון';
          span.style.color = 'green';
        } else if (score > 0) {
          span.innerHTML = `${(score * 100).toFixed(1)}% נכון`;
          header.style.background =
            'linear-gradient(to left, #ffa50085 60%, rgba(0,0,0,0)';
          span.style.color = 'orange';
        } else {
          header.style.background =
            'linear-gradient(to left, #fa807275 60%, rgba(0,0,0,0)';
          span.innerHTML = 'לא נכון';
          span.style.color = 'red';
        }

        span.style.position = 'absolute';
        span.style.left = '10px';
        header.appendChild(span);

        // Differentiate choices: Correct, Incorrect, and User-selected
        const questionValue = Array.isArray(qValue) ? qValue : [qValue];

        if (question.choices) {
          question.choices.forEach((choice) => {
            const choiceValue = choice.value;
            const choiceElement = options.htmlElement.querySelector(
              `input[value="${choiceValue}"]`
            )
              ? options.htmlElement.querySelector(
                  `input[value="${choiceValue}"]`
                )
              : options.htmlElement.querySelector(
                  `input[name="${choiceValue}"]`
                );

            if (choiceElement) {
              const labelElement = choiceElement.parentElement;

              // Reset styles
              labelElement.classList.remove('correct', 'incorrect', 'selected');
              //   console.log(questionValue);

              // Check correctness and style accordingly
              if (userCorrectAnswers.includes(choiceValue)) {
                if (questionValue.includes(choiceValue)) {
                  labelElement.classList.add('correct', 'selected');
                  labelElement.style.color = 'green'; // Correct and selected
                  labelElement.style.fontWeight = 'bold';
                  labelElement.style.backgroundColor = '#d4f4dd';
                } else {
                  labelElement.classList.add('correct');
                  labelElement.style.color = 'blue'; // Correct but not selected
                }
              } else if (questionValue.includes(choiceValue)) {
                labelElement.classList.add('incorrect', 'selected');
                labelElement.style.color = 'red'; // Incorrect and selected
                labelElement.style.textDecoration = 'line-through';
                labelElement.style.backgroundColor = '#f8d7da';
              } else {
                labelElement.style.color = 'inherit'; // Default styling for unselected choices
              }
            }
          });
        }
      });

      survey.mode = 'display';
      setKey(uniqueId());
    }
  }, [dataAnswers]);

  return <Survey model={survey} key={key} />;
};

export default ShowCorrectAndIncorrectSurveyAnswers;
