import { Add, Remove } from '@mui/icons-material';
import { Card, Grid, IconButton, Stack, Typography } from '@mui/material';
import { range } from 'lodash';
import PropTypes from 'prop-types';
import { useMemo } from 'react';
import theme from 'src/theme';

const RatingTypeQuestion = ({
  question,
  setEvalValues,
  questionIndex,
  pageIndex,
  evalValues
}) => {
  RatingTypeQuestion.propTypes = {
    question: PropTypes.object,
    evalValues: PropTypes.object,
    questionIndex: PropTypes.number,
    pageIndex: PropTypes.number,
    setEvalValues: PropTypes.func
  };

  const rates = useMemo(
    () =>
      question.rateValues ||
      [...range(question.rateMin, question.rateMax + 1)].reverse(),
    [question, evalValues]
  );

  const handleSaveQuestionRatingScaleChange = (newText, originalVal) => {
    const tempVals = { ...evalValues };
    const tempQ = {
      ...question,
      autoGenerate: false
    };

    const mapped = rates.map((rate) => {
      if (rate === originalVal || rate.value === originalVal) {
        return {
          value: originalVal.value || originalVal, // it can be a number or an obj after the first change
          text: newText
        };
      }
      return rate;
    });

    tempQ.rateValues = mapped;
    tempVals.pages[pageIndex].elements[questionIndex].rateValues = mapped;

    setEvalValues(tempVals);
  };

  const handleChangeRateDescriptionLabel = (name, newLabel) => {
    const tempQ = {
      ...question
    };

    tempQ[name] = newLabel;
    const temp = { ...evalValues };
    temp.pages[pageIndex].elements[questionIndex] = {
      ...tempQ
    };
    setEvalValues(temp);
  };

  const incrementRatingScales = (valToInc) => {
    const tempQ = {
      ...question
    };

    if (
      (valToInc === -1 && tempQ.rateMax > 1) ||
      (valToInc === 1 && tempQ.rateMax < 20)
    ) {
      tempQ.rateMax += valToInc;
    }

    const temp = { ...evalValues };
    temp.pages[pageIndex].elements[questionIndex] = {
      ...tempQ,
      ratesCount: tempQ.rateMax - tempQ.rateMin
    };
    setEvalValues(temp);
  };

  return (
    <Grid
      sx={{ display: 'flex', alignItems: 'center', overflow: 'auto' }}
      container
      spacing={2}
    >
      <Grid item xs={12} sm="auto">
        <Typography
          variant="caption"
          contentEditable={Boolean(true)}
          suppressContentEditableWarning={Boolean(true)}
          id={`${question.name}-maxRateDescription`}
          name={`${question.name}-maxRateDescription`}
          onBlur={(e) => {
            handleChangeRateDescriptionLabel(
              'maxRateDescription',
              e.target.textContent
            );
          }}
          className="contentEditable"
        >
          {question.maxRateDescription}
        </Typography>
      </Grid>
      <Grid item xs="auto">
        <Stack
          direction="row"
          gap={1}
          sx={{
            display: 'flex',
            flexWrap: 'wrap',
            width: 'fit-content !important',
            alignItems: 'flex-end',
            mx: 1
          }}
        >
          {rates &&
            rates.map((scale) => {
              const scaleVal = scale.value || scale;
              const scaleId = `${question.name}-ratingValue${scaleVal}`;
              return (
                <Card
                  sx={{
                    borderRadius: '3rem',
                    minWidth: '48px',
                    width: 'fit-content',
                    height: '48px',
                    px: 1
                  }}
                  elevation={4}
                  key={`card-${scaleVal}`}
                >
                  <Typography
                    id={scaleId}
                    sx={{
                      height: '100%',
                      textAlign: 'center',
                      lineHeight: '48px'
                    }}
                  >
                    {scale.text || scale}
                  </Typography>
                </Card>
              );
            })}
        </Stack>
      </Grid>
      <Grid item xs={12} sm="auto">
        <Typography
          variant="caption"
          contentEditable={Boolean(true)}
          suppressContentEditableWarning={Boolean(true)}
          className="contentEditable"
          id={`${question.name}-minRateDescription`}
          name={`${question.name}-minRateDescription`}
          onBlur={(e) => {
            handleChangeRateDescriptionLabel(
              'minRateDescription',
              e.target.textContent
            );
          }}
        >
          {question.minRateDescription}
        </Typography>
      </Grid>
      <Grid item xs={12} sx={{ pb: 2 }}>
        <Stack direction="row" gap={1}>
          <IconButton
            size="small"
            sx={{
              outline: `1px solid ${
                question.rateValues && question.rateValues.length >= 15
                  ? theme.palette.action.disabled
                  : theme.palette.success.main
              }`
            }}
            disabled={question.rateValues && question.rateValues.length >= 15}
            onClick={() => incrementRatingScales(1)}
          >
            <Add
              color={
                question.rateValues && question.rateValues.length >= 15
                  ? 'disabled'
                  : 'success'
              }
            />
          </IconButton>
          <IconButton
            size="small"
            sx={{
              outline: `1px solid ${
                question.rateValues && question.rateValues.length === 1
                  ? theme.palette.action.disabled
                  : theme.palette.error.main
              }`
            }}
            disabled={question.rateValues && question.rateValues.length === 1}
            onClick={() => incrementRatingScales(-1)}
          >
            <Remove
              color={
                question.rateValues && question.rateValues.length === 1
                  ? 'disabled'
                  : 'error'
              }
            />
          </IconButton>
        </Stack>
      </Grid>
    </Grid>
  );
};

export default RatingTypeQuestion;
