const itemTypeChipsProps = {
  role: {
    variant: 'outlined',
    color: 'success'
  },
  department: {
    variant: 'outlined',
    color: 'primary'
  },
  job: {
    variant: 'outlined',
    color: 'secondary'
  }
};

export default itemTypeChipsProps;
