import { Grid, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import AddQuestionButtonGroup from './AddQuestionButtonGroup';
import './evaluation.scss';

const NoQuestionsAdded = ({
  setEvaluationProcessValues,
  evaluationProcessValues,
  componentName
}) => {
  NoQuestionsAdded.propTypes = {
    setEvaluationProcessValues: PropTypes.func,
    evaluationProcessValues: PropTypes.object,
    componentName: PropTypes.string
  };

  return (
    <>
      {/* <Grid item xs={12}>
        <Divider />
      </Grid> */}
      <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
        <Typography sx={{ maxWidth: 220 }} variant="subtitle1">
          נא להתחיל להוסיף שאלות
        </Typography>
      </Grid>
      <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
        <AddQuestionButtonGroup
          currentPage={0}
          currentQuestion={0}
          evalProcessValues={evaluationProcessValues}
          setEvalValues={setEvaluationProcessValues}
          componentName={componentName}
          key="adding-question-btn-group-empty-state"
        />
      </Grid>
    </>
  );
};

export default NoQuestionsAdded;
