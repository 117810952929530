const checkIfQuestionsAlreadyAdded = (evalProcess) => {
  if (!evalProcess) {
    return false;
  }
  const { pages } = evalProcess;
  if (!pages || pages.length === 0) {
    return false;
  }
  const { elements } = pages[0];
  if (!elements || elements.length === 0) {
    return false;
  }
  return true;
};

export default checkIfQuestionsAlreadyAdded;
