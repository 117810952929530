const compareRolesOrders = (
  currentUserRbac,
  rbacOfUserToCompare,
  department,
  rbacDetails
) => {
  if (currentUserRbac && rbacOfUserToCompare && department) {
    const indexOfDepartmentInCurrentUser = currentUserRbac.findIndex(
      (el) => el.department_id === department
    );
    const indexOfDepartmentInUserToCompare = rbacOfUserToCompare.findIndex(
      (el) => el.department_id === department
    );
    if (
      indexOfDepartmentInCurrentUser > -1 &&
      indexOfDepartmentInUserToCompare > -1
    ) {
      const currentUserRoleRefInDepartment =
        currentUserRbac[indexOfDepartmentInCurrentUser].role_ref;
      const userToCompareRoleRefInDepartment =
        rbacOfUserToCompare[indexOfDepartmentInUserToCompare].role_ref;
      if (currentUserRoleRefInDepartment && userToCompareRoleRefInDepartment) {
        // check whom is ranked higher
        const { roles } = rbacDetails;
        const indexOfCurrentUserRoleRefInDepartmentRoleInGlobal =
          roles.findIndex(
            (el) => el.role_id === currentUserRoleRefInDepartment
          );
        const indexOfUserToCompareRoleRefInDepartmentRoleInGlobal =
          roles.findIndex(
            (el) => el.role_id === userToCompareRoleRefInDepartment
          );
        if (
          indexOfCurrentUserRoleRefInDepartmentRoleInGlobal > -1 &&
          indexOfUserToCompareRoleRefInDepartmentRoleInGlobal > -1
        ) {
          const orderOfCurrent =
            roles[indexOfCurrentUserRoleRefInDepartmentRoleInGlobal].order;
          const orderToCompare =
            roles[indexOfUserToCompareRoleRefInDepartmentRoleInGlobal].order;
          if (orderOfCurrent && orderToCompare) {
            return (orderOfCurrent.v || 0) < (orderToCompare.v || 0);
          }
          if (!orderOfCurrent && orderToCompare) {
            return false;
          }
          if (orderOfCurrent && !orderToCompare) {
            return true;
          }
          return false;
        }
      }
      if (currentUserRoleRefInDepartment && !userToCompareRoleRefInDepartment) {
        return true;
      }
      return false;
    }
  }
  return false;
};

export default compareRolesOrders;
