import {
  Add,
  AddCircleOutlineOutlined,
  HighlightOffOutlined,
  RemoveOutlined
} from '@mui/icons-material';
import {
  Checkbox,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Stack,
  Typography
} from '@mui/material';
import { alpha } from '@mui/material/styles';
import { uniqueId } from 'lodash';
import PropTypes from 'prop-types';
import { useMemo } from 'react';
import theme from 'src/theme';
import getQuestionDefaultProps from './getQuestionDefaultProps';

const CheckboxTypeQuestion = ({
  question,
  setEvalValues,
  questionIndex,
  pageIndex,
  evalValues,
  isCorrectAnswersModeActive,
  correctAnswers,
  setCorrectAnswers,
  handleMarkAnswerAsCorrect
}) => {
  CheckboxTypeQuestion.propTypes = {
    question: PropTypes.object,
    evalValues: PropTypes.object,
    questionIndex: PropTypes.number,
    pageIndex: PropTypes.number,
    setEvalValues: PropTypes.func,
    isCorrectAnswersModeActive: PropTypes.bool,
    correctAnswers: PropTypes.array,
    setCorrectAnswers: PropTypes.func,
    handleMarkAnswerAsCorrect: PropTypes.func
  };

  const getOrDefault = (opt1, opt2) => opt1 || opt2;

  const handleSaveCheckboxQuestionChoiceLabelChange = (
    newLabel,
    choiceIndex
  ) => {
    const tempVals = { ...evalValues };
    const tempQ = { ...question };
    const editedChoice = tempQ.choices[choiceIndex];

    // Update the choice's text
    editedChoice.text = newLabel;

    // Update the value for the choice if needed
    const oldValue = editedChoice.value;
    const newValue = `p${pageIndex}-q${questionIndex}-ch${choiceIndex}`;
    editedChoice.value = newValue;

    // If the old value is in correctAnswers, update it
    // if (correctAnswers.includes(oldValue)) {
    //   const updatedCorrectAnswers = correctAnswers.map((val) => {
    //     if (val === oldValue) {
    //       return newValue;
    //     }
    //     return val;
    //   });
    //   setCorrectAnswers(updatedCorrectAnswers);
    // }

    // Save updated question
    tempVals.pages[pageIndex].elements[questionIndex] = {
      ...question,
      ...tempQ
    };

    setEvalValues(tempVals);
  };

  const qChoices = useMemo(
    () =>
      getOrDefault(
        question.choices,
        getQuestionDefaultProps(question.type, questionIndex, pageIndex).choices
      ),
    [question]
  );

  const handleRemoveChoice = (choiceIndexToRemove, answerVal) => {
    const tempVals = { ...evalValues };
    const tempQ = { ...question };

    // Remove the choice from Q choices
    tempQ.choices.splice(choiceIndexToRemove, 1);
    const mappedChoices = tempQ.choices.map((choice, i) => ({
      text: `בחירה ${i}`,
      ...choice,
      value: `p${pageIndex}-q${questionIndex}-ch${i}`
    }));

    // Update correctAnswers to only include valid choices
    const validCorrectAnswers = correctAnswers.filter((answer) =>
      tempQ.choices.some((choice) => choice.value === answer)
    );
    const tempValidCorrectAnswers = validCorrectAnswers.filter(
      (el) => el === answerVal
    );

    // Update local temp states
    tempQ.correctAnswer = validCorrectAnswers;
    tempQ.choices = mappedChoices;
    tempVals.pages[pageIndex].elements[questionIndex] = tempQ;

    // Persist changes
    setEvalValues(tempVals);
    setCorrectAnswers(tempValidCorrectAnswers);
  };

  const handleAddChoice = () => {
    const tempVals = { ...evalValues };
    const tempQ = { ...question };
    const mappedChoices = tempQ.choices.map((choice, i) => ({
      text: `בחירה ${i}`,
      ...choice,
      value: `p${pageIndex}-q${questionIndex}-ch${i}`
    }));
    mappedChoices.push({
      text: `בחירה ${mappedChoices.length + 1}`,
      value: `p${pageIndex}-q${questionIndex}-ch${mappedChoices.length}`
    });
    tempVals.pages[pageIndex].elements[questionIndex].choices = mappedChoices;
    setEvalValues(tempVals);
  };

  const handleChangeColCount = (colChange) => {
    const tempVals = { ...evalValues };
    const tempQ = { ...question };
    tempQ.colCount += colChange;
    tempVals.pages[pageIndex].elements[questionIndex] = tempQ;
    setEvalValues(tempVals);
  };

  return (
    <Grid
      sx={{
        display: 'flex',
        alignItems: 'center'
      }}
      container
      spacing={2}
    >
      <Grid item xs={12}>
        <Stack
          direction="row"
          gap={1}
          sx={{ alignItems: 'center', position: 'relative' }}
        >
          <IconButton
            onClick={() => handleChangeColCount(1)}
            disabled={question.colCount >= 3}
          >
            <Add />
          </IconButton>
          <Typography sx={{ p: 1 }}>
            {getOrDefault(question.colCount, 1)}
          </Typography>
          <IconButton
            onClick={() => handleChangeColCount(-1)}
            disabled={question.colCount === 1}
          >
            <RemoveOutlined />
          </IconButton>
          <Typography
            sx={{ position: 'absolute', bottom: -7, left: '20px' }}
            color="GrayText"
            variant="caption"
          >
            כמה עמודי תשובות?
          </Typography>
        </Stack>
      </Grid>
      <Grid
        item
        xs={12}
        // sm="auto"
        sx={{
          transition: 'all 0.5s linear'
        }}
      >
        <List
          sx={{
            display: 'grid',
            gridTemplateColumns: `repeat(${getOrDefault(
              question.colCount,
              1
            )}, minmax(200px, 1fr))`,
            gap: 2,
            transition: 'all 1s linear'
          }}
        >
          {qChoices.map((option, i) => (
            <ListItem
              key={uniqueId('option', option.value)}
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'flex-start',
                flexBasis: `max(${100 / qChoices.colCount}%, 200px)`,
                minWidth: `${100 / question.colCount}%`,
                backgroundColor:
                  correctAnswers.includes(option.value) &&
                  alpha(theme.palette.success.light, 0.1),
                transition: 'all 1s linear'
              }}
            >
              <ListItemAvatar sx={{ transition: 'all linear 0.3s' }}>
                {isCorrectAnswersModeActive ? (
                  <Checkbox
                    color="success"
                    size="small"
                    onChange={(e, checked) => {
                      handleMarkAnswerAsCorrect(checked, option.value);
                      // console.log(checked);
                      // handleMarkAnswerAsCorrect(checked, option);
                    }}
                    checked={
                      correctAnswers && correctAnswers.includes(option.value)
                    }
                  />
                ) : (
                  <IconButton
                    onClick={() => handleRemoveChoice(i, option.value)}
                    color="error"
                    disabled={qChoices.length === 1 && i === 0}
                    size="small"
                  >
                    <HighlightOffOutlined />
                  </IconButton>
                )}
              </ListItemAvatar>
              <ListItemText
                primary={option.text}
                primaryTypographyProps={{
                  contentEditable: true,
                  suppressContentEditableWarning: true,
                  id: `page${pageIndex}-question${questionIndex}-choice${i}`,
                  className: 'contentEditable',
                  onBlur: (e) => {
                    handleSaveCheckboxQuestionChoiceLabelChange(
                      e.currentTarget.textContent,
                      i
                    );
                  },
                  sx: {
                    textAlign: 'start',
                    width: '100%'
                  },
                  onClick: (e) => {
                    // e.target.style.position = 'absolute';
                    e.target.style.background = alpha(
                      theme.palette.info.light,
                      0.2
                    );
                    e.target.style.zIndex = 10;
                    // e.target.style.minWidth = '300px';
                    e.target.style.transition = 'all linear 0.3s';
                  }
                }}
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-start',
                  alignItems: 'center'
                  //   flex: 1
                }}
              />
            </ListItem>
          ))}
          <ListItem key={uniqueId('newOptionSuggestion')}>
            <ListItemAvatar
              sx={{
                width: 'fit-content',
                display: 'flex',
                flexDirection: 'row',
                gap: 0.5
              }}
            >
              {qChoices.length <= 20 && (
                <IconButton
                  onClick={() => handleAddChoice()}
                  color="success"
                  disabled={qChoices.length === 20}
                  size="small"
                >
                  <AddCircleOutlineOutlined />
                </IconButton>
              )}
            </ListItemAvatar>
            <ListItemText
              primary={
                qChoices.length <= 20
                  ? `בחירה ${qChoices.length + 1}`
                  : 'אפשר להוסיף עד 20 אפשרויות'
              }
              primaryTypographyProps={{
                contentEditable: true,
                suppressContentEditableWarning: true,
                className: 'contentEditable',
                color: theme.palette.action.disabled,
                id: `page${pageIndex}-question${questionIndex}`,
                onFocus: (e) => {
                  handleAddChoice();
                  const el = document.getElementById(
                    `page${pageIndex}-question${questionIndex}-choice${
                      qChoices.length - 1
                    }`
                  );
                  if (el) {
                    el.focus();
                  }
                }
              }}
            />
          </ListItem>
        </List>
      </Grid>
    </Grid>
  );
};

export default CheckboxTypeQuestion;
