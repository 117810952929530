import {
  collection,
  doc,
  getDoc,
  getDocs,
  limit,
  orderBy,
  query,
  where
} from '@firebase/firestore';
import { db } from 'src/_firebase/firebase';

const getUserSignature = async (signaturesPath, user_doc_id, knowledgePost) => {
  if (knowledgePost) {
    try {
      const responseRef = collection(
        db,
        `orgs/${knowledgePost.org_ref}/knowledgeTests/${knowledgePost.post_id}/responses/${user_doc_id}/history`
      );
      const q = query(responseRef, orderBy('done_at', 'desc'), limit(1));
      const lastResDocs = await getDocs(q);
      if (lastResDocs.empty) {
        return {};
      }

      return { ...lastResDocs.docs[0].data() };
    } catch (err) {
      console.error('Error fetching user responses:', err);
      return {};
    }
  }

  try {
    const signRef = doc(db, `${signaturesPath}/${user_doc_id}`);
    const userSign = await getDoc(signRef);

    if (userSign.exists()) {
      return { ...userSign.data() };
    }

    return {};
  } catch (err) {
    console.error('Error fetching user signature:', err);
    return {};
  }
};

export default getUserSignature;
