import {
  addDoc,
  collection,
  doc,
  serverTimestamp,
  updateDoc
} from '@firebase/firestore';
import moment from 'moment';
import { db } from 'src/_firebase/firebase';

const handleSaveEvaluationProcess = async (newValues, org_ref) => {
  const { evaluation_id } = newValues;
  const valsToSave = {
    ...newValues,
    labels: newValues.labels
      ? newValues.labels.map((label) => {
          const { item_type, id, name } = label;
          return { item_type, id, name };
        })
      : []
  };
  if (!evaluation_id) {
    // new process
    const evalCollection = collection(db, `orgs/${org_ref}/evaluations`);
    return addDoc(evalCollection, {
      ...valsToSave,
      last_modified: serverTimestamp(),
      org_ref
    })
      .then((res) => ({ ...valsToSave, evaluation_id: res.id, org_ref }))
      .catch((err) => {
        console.log(err);
        return null;
      });
  }
  // console.log(valsToSave);
  const evalDoc = doc(db, `orgs/${org_ref}/evaluations/${evaluation_id}`);
  return updateDoc(evalDoc, {
    ...valsToSave,
    last_modified: serverTimestamp()
  })
    .then(() => ({
      ...valsToSave,
      last_modified: {
        seconds: moment().unix()
      }
    }))
    .catch((err) => {
      console.log(err);
      return null;
    });
};

export default handleSaveEvaluationProcess;
