import { TextField } from '@mui/material';
import PropTypes from 'prop-types';

const TextTypeQuestion = ({ question }) => {
  TextTypeQuestion.propTypes = {
    question: PropTypes.object
  };
  return <TextField fullWidth id={question.name} name={question.name} />;
};

export default TextTypeQuestion;
