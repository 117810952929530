import moment from 'moment';

/**
 *
 * @param {String} questionType
 * @param {Number} currentQuestionIndex
 * @returns default Question object
 */
const getQuestionDefaultProps = (
  questionType = 'radiogroup',
  currentQuestionIndex,
  currentPage
) => {
  const defaultChoices = [
    {
      text: 'בחירה 1',
      value: `p${currentPage}-q${currentQuestionIndex}-ch0`
    },
    {
      text: 'בחירה 2',
      value: `p${currentPage}-q${currentQuestionIndex}-ch1`
    },
    {
      text: 'בחירה 3',
      value: `p${currentPage}-q${currentQuestionIndex}-ch2`
    }
  ];
  const defualtProps = {
    title: `שאלה ${currentQuestionIndex === 0 ? 1 : currentQuestionIndex + 1}`,
    type: questionType || 'radiogroup',
    name: `question${currentQuestionIndex}-page${currentPage}-${moment().unix()}`,
    startWithNewLine: true
  };
  // if (scoring) {
  //   const { scoringMethod, numOfQuestions } = scoring;

  //   defualtProps.score =
  //     scoringMethod === 'onlyRequired' ? 0 : numOfQuestions / 1;
  // }
  switch (defualtProps.type) {
    case 'rating':
      return {
        ...defualtProps,
        rateMin: 0,
        rateMax: 5,
        maxRateDescription: '(ביצוע טוב מאוד)',
        minRateDescription: '(ביצוע נמוך)'
      };
    case 'checkbox':
      return {
        ...defualtProps,
        choices: defaultChoices,
        colCount: 1
      };
    case 'radiogroup':
      return {
        ...defualtProps,
        choices: defaultChoices,
        colCount: 1
      };
    default:
      return {
        ...defualtProps
      };
  }
};

export default getQuestionDefaultProps;
