import jobsTitles from 'src/common/jobsTitles';

/**
 *
 * @param {*} option
 * @param {*} rbacDetails
 * @returns String
 */
const getJobTitlesAndRolesLabel = (option, rbacDetails) => {
  const { item_type, id } = option;
  if (item_type === 'job') {
    const index = jobsTitles.findIndex((el) => el.name === id);
    if (index > -1) {
      return jobsTitles[index].label;
    }
  }
  if (item_type === 'role' && rbacDetails && rbacDetails.roles) {
    const index = rbacDetails.roles.findIndex((el) => el.role_id === id);
    if (index > -1) {
      return rbacDetails.roles[index].role_name;
    }
  }
};

export default getJobTitlesAndRolesLabel;
