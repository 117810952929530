import {
  DeleteForeverOutlined,
  MoreVertOutlined,
  VisibilityOutlined
} from '@mui/icons-material';
import {
  Button,
  FormControlLabel,
  IconButton,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack
} from '@mui/material';
import Menu from '@mui/material/Menu';
import PropTypes from 'prop-types';
import { useEffect, useMemo, useState } from 'react';
import IOSSwitch from 'src/components/shared/IOSSwitch/IOSSwitch';
import { useSelectedOrg } from 'src/hooks/useSelectedOrg/useSelectedOrg';
import { useCurrentUserContext } from 'src/hooks/useUserContext/UserContext';
import theme from 'src/theme';
import compareRolesOrders from 'src/utils/compareRolesOrders';
import getOrgSpecificDetails from 'src/utils/getOrgSpecificDetails';
import isAuthorized from 'src/utils/isAuthorized';

export default function CommunityPostItemMoreMenu({
  post,
  handleOpenEditDialog,
  handleOpenDeletePostDialog,
  isPublished,
  handleChangePublishState,
  handleOpenChooseKnowledgeTestVisibileForDialog
}) {
  CommunityPostItemMoreMenu.propTypes = {
    post: PropTypes.any,
    handleOpenEditDialog: PropTypes.func,
    handleOpenDeletePostDialog: PropTypes.func,
    isPublished: PropTypes.bool,
    handleOpenChooseKnowledgeTestVisibileForDialog: PropTypes.func,
    handleChangePublishState: PropTypes.func
  };

  const { currentUser } = useCurrentUserContext();
  const { selectedOrg } = useSelectedOrg();

  const [anchorEl, setAnchorEl] = useState(null);

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleEditPost = () => {
    handleClose();
  };

  const isAuth = useMemo(() => {
    if (currentUser) {
      const { type } = currentUser;
      if (type === 'org') {
        return true;
      }
      if (selectedOrg) {
        if (isAuthorized(currentUser, 'practices:write', selectedOrg)) {
          if (post.author_ref === currentUser.user_doc_id) {
            return true;
          }
          return false;
        }
        return false;
      }
      return false;
    }
    return false;
  }, [currentUser, selectedOrg]);

  return (
    <Stack gap={0} direction="row" alignItems="center">
      {post.knowledgeTest ? (
        <>
          {isAuth && (
            <Button
              onClick={() =>
                handleOpenChooseKnowledgeTestVisibileForDialog(post)
              }
              endIcon={<VisibilityOutlined />}
              variant="outlined"
            >
              מי יראה?
            </Button>
          )}
        </>
      ) : (
        <FormControlLabel
          checked={isPublished}
          // label="פרסום"
          // labelPlacement="start"
          sx={{ mx: 0 }}
          control={<IOSSwitch />}
          onChange={(e, newState) => handleChangePublishState(newState)}
          id={`${post.post_id}-post-publish-switch`}
        />
      )}
      <IconButton
        id={`more-menu${post.post_id}-btn`}
        aria-controls={open ? `more-menu${post.post_id}` : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        <MoreVertOutlined />
      </IconButton>
      <Menu
        id={`more-menu${post.post_id}`}
        aria-labelledby={`more-menu${post.post_id}-btn`}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
      >
        {/* <ListItemButton onClick={() => handleOpenEditDialog(post)}>
          <ListItemIcon sx={{ width: 'fit-content !important', minWidth: 40 }}>
            <EditOutlined />
          </ListItemIcon>
          <ListItemText primary="עריכה" sx={{ minWidth: 60 }} />
        </ListItemButton> */}
        {/* <Divider sx={{ width: '85%', mx: 'auto' }} /> */}
        <ListItemButton
          onClick={() => {
            handleOpenDeletePostDialog(post);
            console.log(post);
          }}
        >
          <ListItemIcon sx={{ width: 'fit-content !important', minWidth: 40 }}>
            <DeleteForeverOutlined color="error" />
          </ListItemIcon>
          <ListItemText
            primary="מחיקה"
            sx={{ minWidth: 60, color: theme.palette.error.main }}
          />
        </ListItemButton>
      </Menu>
    </Stack>
  );
}
