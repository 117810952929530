import { doc, serverTimestamp, updateDoc } from '@firebase/firestore';
import { ArrowBackOutlined } from '@mui/icons-material';
import {
  Backdrop,
  Button,
  Card,
  CardActionArea,
  CardMedia,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  Grow,
  IconButton,
  Typography
} from '@mui/material';
import moment from 'moment';
import { bool, func, object } from 'prop-types';
import { useEffect, useState } from 'react';
import { db } from 'src/_firebase/firebase';
import employee from 'src/assets/knowledgeCommunity/businessman.png';
import team from 'src/assets/knowledgeCommunity/team.png';
import { useSnackbar } from 'src/hooks/useSnackbar/useSnackbar';
import { useCurrentUserContext } from 'src/hooks/useUserContext/UserContext';
import theme from 'src/theme';
import NewPostLabels from '../AddNewPost/NewPostLabels';
import MultipleEmployeesSelect from './MultipleEmployeesSelect';

const ChooseVisibileForDialog = ({ open, onClose, post }) => {
  ChooseVisibileForDialog.propTypes = {
    open: bool,
    onClose: func,
    post: object
  };
  const { currentUser } = useCurrentUserContext();
  const { snackbarState, setSnackbarState } = useSnackbar();
  const [activeView, setActiveView] = useState();
  const [newPostValues, setNewPostValues] = useState();
  const [selectedEmployeesThatCanExamine, setSelectedEmployeesThatCanExamine] =
    useState();
  const [isLoading, setLoading] = useState(false);
  const handleChangeView = (newVal) => setActiveView(newVal);

  const handleChangeEmployeesList = (employeesList) => {
    setNewPostValues({
      ...newPostValues,
      allowed_employees: employeesList.map((el) => el.doc_id)
    });
  };

  const handleSaveLabelsAndSpecificEmployees = () => {
    // save the new post values and the selected employees
    // and close the dialog
    setLoading(true);
    const { org_ref, knowledge_test_id, allowed_employees, labels } = post;
    const testDocRef = doc(
      db,
      `/orgs/${org_ref}/knowledgeTests/${knowledge_test_id}/`
    );
    const dataToSave = {
      last_modified_at: serverTimestamp(),
      last_modified_by: currentUser.user_doc_id
    };
    // update the local state
    post.last_modified_by = dataToSave.last_modified_by;
    post.last_modified_at = {
      seconds: moment().unix()
    };
    if (allowed_employees) {
      dataToSave.allowed_employees = allowed_employees;
    }
    if (labels) {
      dataToSave.labels = labels;
    }

    updateDoc(testDocRef, dataToSave)
      .then(() => {
        console.log('saved successfully');

        setSnackbarState({
          ...snackbarState,
          open: true,
          msg: 'השינויים נשמרו בהצלחה',
          severity: 'success'
        });
        setLoading(false);
        onClose();
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
        setSnackbarState({
          ...snackbarState,
          open: true,
          msg: 'לא הצלחנו לשמור את השינויים, נא לנסות שנית!',
          severity: 'error'
        });
      });
  };

  useEffect(() => {
    if (
      activeView === 'specificEmployees' &&
      newPostValues &&
      !selectedEmployeesThatCanExamine
    ) {
      setSelectedEmployeesThatCanExamine(newPostValues.allowed_employees || []);
    }
  }, [activeView, newPostValues]);

  useEffect(() => {
    if (post && !newPostValues) {
      setNewPostValues(post);
    }
  }, [post]);
  return (
    <>
      <Dialog fullWidth maxWidth="md" onClose={onClose} open={open}>
        <DialogTitle
          sx={{
            display: 'flex',
            justifyContent: 'space-between'
          }}
        >
          <Typography variant="inherit" fontSize="inherit">
            למי יוצג הפרסום
          </Typography>
          {Boolean(activeView) && (
            <Grow in={Boolean(activeView)}>
              <IconButton onClick={() => handleChangeView()}>
                <ArrowBackOutlined />
              </IconButton>
            </Grow>
          )}
        </DialogTitle>
        <Divider />
        <DialogContent>
          {activeView === 'specificEmployees' ? (
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography>בחר את העובדים שתרצה שיראו את המבדק</Typography>
              </Grid>
              <Grid item xs={12}>
                <MultipleEmployeesSelect
                  selectedEmployees={selectedEmployeesThatCanExamine}
                  setSelectedEmployees={setSelectedEmployeesThatCanExamine}
                  callback={handleChangeEmployeesList}
                  customSx={{
                    maxWidth: 650
                  }}
                />
              </Grid>
            </Grid>
          ) : activeView === 'labeledGroup' ? (
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography>בחר תפקידים שמבדק זה מתאים להם</Typography>
              </Grid>
              <Grid item xs={12}>
                <NewPostLabels
                  newPostValues={newPostValues}
                  setNewPostValues={setNewPostValues}
                  customSx={{
                    maxWidth: 650
                  }}
                  key={`labeledGroup-visibile-for-${post.post_id}`}
                />
              </Grid>
            </Grid>
          ) : (
            <Grid container spacing={2}>
              {[
                {
                  id: 'labeledGroup',
                  label: 'קבוצה לפי תקפיד',
                  img: team,
                  bg: 'lightgoldenrodyellow',
                  color: 'darkcyan'
                },
                {
                  id: 'specificEmployees',
                  label: 'עובדים ספציפיים',
                  img: employee,
                  bg: 'lightcyan',
                  color: 'darkgoldenrod'
                }
              ].map((option) => (
                <Grid item xs={12} md={6} key={option.id}>
                  <Card
                    sx={{ height: '35vh', bgcolor: option.bg }}
                    elevation={4}
                  >
                    <CardActionArea
                      sx={{
                        position: 'relative',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '100%'
                      }}
                      onClick={() => handleChangeView(option.id)}
                    >
                      <CardMedia
                        component="img"
                        height="300"
                        image={option.img}
                        alt={option.label}
                        sx={{
                          filter: 'opacity(0.3)'
                        }}
                      />
                      <Typography
                        sx={{ position: 'absolute', color: option.color }}
                        variant="h1"
                      >
                        {option.label}
                      </Typography>
                    </CardActionArea>
                  </Card>
                </Grid>
              ))}
            </Grid>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            disabled={!activeView}
            onClick={() => {
              // assign newPostValues keys and values to post
              Object.keys(newPostValues).forEach((key) => {
                post[key] = newPostValues[key];
              });
              handleSaveLabelsAndSpecificEmployees();
            }}
          >
            בחירה
          </Button>
          <Button variant="outlined" onClick={onClose}>
            ביטול
          </Button>
        </DialogActions>
      </Dialog>
      {isLoading && (
        <Backdrop open={isLoading} sx={{ zIndex: theme.zIndex.modal + 1 }}>
          <CircularProgress />
        </Backdrop>
      )}
    </>
  );
};

export default ChooseVisibileForDialog;
