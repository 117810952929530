import {
    Autocomplete,
    Checkbox,
    ListItemButton,
    ListItemText,
    TextField
} from '@mui/material';
import { uniqueId } from 'lodash';
import { array, func, object } from 'prop-types';
import { useEffect, useState } from 'react';
import { useEmployeesData } from 'src/hooks/useEmployeesData/useEmployeesData';
import { useRbacDetails } from 'src/hooks/useRBAC/useRBAC';
import { useSelectedOrg } from 'src/hooks/useSelectedOrg/useSelectedOrg';
import { useCurrentUserContext } from 'src/hooks/useUserContext/UserContext';
import compareRolesOrders from 'src/utils/compareRolesOrders';
import getOrgRef from 'src/utils/getOrgRef';
import getOrgSpecificDetails from 'src/utils/getOrgSpecificDetails';

const MultipleEmployeesSelect = ({
  selectedEmployees = [],
  setSelectedEmployees,
  callback,
  customSx
}) => {
  MultipleEmployeesSelect.propTypes = {
    selectedEmployees: array,
    setSelectedEmployees: func,
    callback: func,
    customSx: object
  };

  const { employeesData } = useEmployeesData();
  const [shownEmployees, setShownEmployees] = useState();
  const { currentUser } = useCurrentUserContext();
  const { rbacDetails } = useRbacDetails();
  const { selectedOrg } = useSelectedOrg();

  const filterEmployeesAuthorizedToExamine = () => {
    const { type } = currentUser;
    if (type === 'org') {
      return employeesData;
    }
    const approvedOnly = employeesData.filter(
      (el) =>
        el.org_specific_details && el.org_specific_details.status === 'approved'
    );
    const org_ref = getOrgRef(currentUser, selectedOrg);
    const authToExamine = [];
    approvedOnly.forEach((employee) => {
      const { org_specific_details } = employee;
      const currentUserRbac = getOrgSpecificDetails(
        currentUser.employee_work_places,
        org_ref
      ).rbac;
      const employeeRbac = org_specific_details
        ? org_specific_details.rbac
        : null;

      if (employeeRbac) {
        const employeeDeps = employeeRbac.map((el) => el.department_id);
        const currentUserDeps = currentUserRbac.map((el) => el.department_id);
        const mutualDeps = currentUserDeps.filter((el) =>
          employeeDeps.includes(el)
        );
        const isHigherInSomeDepsArr = mutualDeps.map((dep_id) =>
          compareRolesOrders(currentUserRbac, employeeRbac, dep_id, rbacDetails)
        );

        if (isHigherInSomeDepsArr.includes(true)) {
          authToExamine.push(employee);
        }
      } else {
        authToExamine.push(employee);
      }
    });

    return authToExamine;
  };

  useEffect(() => {
    if (employeesData && !shownEmployees) {
      const filtered = filterEmployeesAuthorizedToExamine();
      setShownEmployees(filtered);
    }
  }, [employeesData]);

  return (
    <Autocomplete
      loading={!shownEmployees}
      disableCloseOnSelect
      options={shownEmployees || []}
      getOptionLabel={(option) => `${option.first_name} ${option.last_name}`} // Display names
      onChange={(e, val) => {
        const selectedDocIds = val.map((el) => el.doc_id); // Extract doc_ids
        setSelectedEmployees(selectedDocIds); // Update with doc_ids
        if (callback) {
          callback(val); // Pass full objects if needed
        }
      }}
      isOptionEqualToValue={(opt, val) => opt.doc_id === val.doc_id} // Compare by doc_id
      value={
        shownEmployees
          ? shownEmployees.filter((emp) =>
              selectedEmployees.includes(emp.doc_id)
            )
          : []
      } // Match doc_ids to objects for display
      multiple
      renderOption={(props, opt) => (
        <ListItemButton
          {...props}
          key={`${opt.doc_id}-${uniqueId('employee-option')}`}
        >
          <Checkbox checked={selectedEmployees.includes(opt.doc_id)} />
          <ListItemText
            primary={`${opt.first_name} ${opt.last_name}`}
            secondary={opt.id}
          />
        </ListItemButton>
      )}
      sx={{
        ...customSx
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          fullWidth
          label="עובדים"
          placeholder="בחר עובדים"
        />
      )}
      noOptionsText="אין עובדים, נא להוסיף קודם"
    />
  );
};

export default MultipleEmployeesSelect;
