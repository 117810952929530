import {
  DeleteOutlineOutlined,
  DragHandleOutlined,
  FileCopyOutlined
} from '@mui/icons-material';
import {
  Box,
  Button,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  Icon,
  Slide
} from '@mui/material';
import { uniqueId } from 'lodash';
import {
  sortableContainer,
  sortableElement,
  sortableHandle
} from 'react-sortable-hoc';
import AddQuestionButtonGroup from './AddQuestionButtonGroup';
import './evaluation.scss';
import QuestionsPageLayout from './QuestionsPageLayout';
import SurveyQuestionElement from './SurveyQuestionElement';

const DragHandle = sortableHandle(() => (
  <Icon
    sx={{
      cursor: 'grab',
      position: 'absolute',
      left: '50%',
      top: 10,
      filter: 'opacity(0.5)'
    }}
    className="q-survey-handle"
  >
    <DragHandleOutlined color="action" />
  </Icon>
));

const SortableItem = sortableElement(({ value }) => (
  <Box
    sx={{ position: 'relative', my: 2 }}
    key={`question-element-${value.questionIndex}-${
      value.pageIndex
    }-${uniqueId()}`}
  >
    <DragHandle />
    <SurveyQuestionElement
      surveyType={value.surveyType}
      {...value}
      key={`question-element-${value.question.name}-${
        value.pageIndex
      }-${uniqueId()}`}
    />
    <Divider sx={{ width: '50%', mx: 'auto' }} />
  </Box>
));

const SortableList = sortableContainer(
  ({
    items,
    handleAddNewPage,
    handleChangePageTitle,
    handleDeletePage,
    evaluationProcessValues,
    setEvaluationProcessValues,
    handleDuplicatePage,
    componentName,
    surveyType
  }) => (
    <Grid
      xs={12}
      item
      container
      spacing={2}
      sx={{
        p: 2,
        backgroundColor: '#f3f3f39e',
        mt: 3,
        justifyContent: 'center',
        marginInlineStart: 0
      }}
    >
      {items.map((page, pageIndex) => (
        <QuestionsPageLayout
          handleAddNewPage={handleAddNewPage}
          pageIndex={pageIndex}
          key={`${uniqueId('questions-page')}-${page.name}`}
        >
          <CardHeader
            title={page.name}
            titleTypographyProps={{
              className: 'contentEditable',
              contentEditable: true,
              suppressContentEditableWarning: true,
              sx: {
                width: 'fit-content',
                fontSize: '22px'
              },
              onBlur: (e) => {
                const el = document.getElementById(`page-${pageIndex}`);
                handleChangePageTitle(el.textContent, pageIndex);
              },
              id: `page-${pageIndex}`
            }}
            action={
              <Box
                sx={{
                  visibility: 'hidden',
                  position: 'absolute',
                  right: 16,
                  top: 16,
                  gap: 1,
                  display: 'flex'
                }}
                className="page-action"
              >
                <Button
                  variant="outlined"
                  color="warning"
                  endIcon={<FileCopyOutlined />}
                  onClick={() => handleDuplicatePage(pageIndex)}
                >
                  שכפול עמוד
                </Button>
                {evaluationProcessValues.pages &&
                  evaluationProcessValues.pages.length !== 1 && (
                    <Slide
                      in={evaluationProcessValues.pages.length !== 1}
                      direction="right"
                      key={`slide-${page.name}-delete-btn`}
                    >
                      <Button
                        variant="outlined"
                        color="error"
                        endIcon={<DeleteOutlineOutlined />}
                        onClick={() => handleDeletePage(pageIndex)}
                      >
                        מחיקת עמוד
                      </Button>
                    </Slide>
                  )}
              </Box>
            }
          />
          <CardContent>
            {page.elements.map((element, index) => (
              <SortableItem
                key={`element-${element.name}`}
                index={index}
                collection={pageIndex}
                value={{
                  question: element,
                  questionIndex: index,
                  pageIndex,
                  evalProcessValues: evaluationProcessValues,
                  setEvalValues: setEvaluationProcessValues,
                  surveyType
                }}
              />
            ))}
          </CardContent>
          <CardActions
            sx={{
              display: 'flex',
              justifyContent: 'center'
            }}
          >
            <AddQuestionButtonGroup
              currentPage={pageIndex}
              currentQuestion={page.elements ? page.elements.length : 0}
              evalProcessValues={evaluationProcessValues}
              componentName={componentName}
              setEvalValues={setEvaluationProcessValues}
              key={`adding-question-btn-group-${page.name}`}
            />
          </CardActions>
        </QuestionsPageLayout>
      ))}
    </Grid>
  )
);

export default SortableList;
