import { Card, CardContent, Grid } from '@mui/material';
import PropTypes from 'prop-types';

const SignatureTypeQuestion = ({
  question,
  setEvalValues,
  questionIndex,
  pageIndex,
  evalValues
}) => {
  SignatureTypeQuestion.propTypes = {
    question: PropTypes.object,
    evalValues: PropTypes.object,
    questionIndex: PropTypes.number,
    pageIndex: PropTypes.number,
    setEvalValues: PropTypes.func
  };

  return (
    <Grid
      sx={{ display: 'flex', alignItems: 'center' }}
      item
      xs={12}
      container
      spacing={2}
    >
      <Grid item xs={12} sm="auto">
        <Card
          sx={{
            width: '300px',
            height: '200px',
            px: 1,
            border: '1px dashed #37363680'
          }}
          elevation={2}
          key={`boolean-card-page${pageIndex}-${questionIndex}`}
        >
          <CardContent
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            חתימה
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

export default SignatureTypeQuestion;
