import { Grid, Grow, TextField } from '@mui/material';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';

const ImageTypeQuestion = ({
  question,
  setEvalValues,
  questionIndex,
  pageIndex,
  evalValues
}) => {
  const [imgUrlValid, setImgUrlValid] = useState();

  const handleChangeImageUrl = (imgUrl) => {
    const tempQ = { ...question };
    tempQ.imageLink = imgUrl;
    const temp = { ...evalValues };
    temp.pages[pageIndex].elements[questionIndex] = { ...tempQ };
    setEvalValues(temp);
  };

  const checkIfLinkValid = (newVal) => {
    const regex = /(http[s]?:\/\/.*\.(?:png|jpg|gif|svg|jpeg))/i;
    return regex.test(newVal);
  };

  useEffect(() => {
    if (question.imageLink) {
      const isValid = checkIfLinkValid(question.imageLink);
      setImgUrlValid(isValid ? 'valid' : 'invalid');
    } else {
      setImgUrlValid(undefined); // Clear validation state if no image link
    }
  }, [question.imageLink]);

  return (
    <Grid sx={{ display: 'flex', alignItems: 'center' }} container spacing={2}>
      <Grid item xs={12}>
        <TextField
          fullWidth
          label="קישור לתמונה"
          sx={{ maxWidth: 650 }}
          onChange={(e) => handleChangeImageUrl(e.target.value)}
          onBlur={(e) => {
            const isValid = checkIfLinkValid(e.target.value);
            setImgUrlValid(isValid ? 'valid' : 'invalid');
          }}
          value={question.imageLink}
          error={imgUrlValid === 'invalid'}
          helperText={
            imgUrlValid === 'invalid'
              ? 'נא להזין קישור תמונה תקין'
              : 'שאלה מסוג תמונה לא תספר בחישוב הציון, היא תשמש לצורך הצגה בלבד או כהמחשה לשאלה מסוימת.'
          }
        />
      </Grid>
      {question.imageLink && imgUrlValid === 'valid' && (
        <Grow in>
          <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
            <img
              src={question.imageLink}
              style={{ maxWidth: 650 }}
              alt={question.name}
            />
          </Grid>
        </Grow>
      )}
    </Grid>
  );
};

ImageTypeQuestion.propTypes = {
  question: PropTypes.object.isRequired,
  evalValues: PropTypes.object.isRequired,
  questionIndex: PropTypes.number.isRequired,
  pageIndex: PropTypes.number.isRequired,
  setEvalValues: PropTypes.func.isRequired
};

export default ImageTypeQuestion;
