import React from 'react';
import PropTypes, { bool, func, object } from 'prop-types';
import {
  Divider,
  ListItem,
  ListItemIcon,
  ListItemText,
  Stack,
  Typography
} from '@mui/material';
import { QuizOutlined } from '@mui/icons-material';
import theme from 'src/theme';
import { useCurrentUserContext } from 'src/hooks/useUserContext/UserContext';
import { lighten, alpha } from '@mui/material/styles';

const KnowledgeTestPreviewNameAndContentListItem = ({
  post,
  isBtn,
  showDivider,
  onClick
}) => {
  const { currentUser } = useCurrentUserContext();
  const shouldSeeLabels =
    currentUser.type === 'org' || post.author_ref === currentUser.user_doc_id;
  return (
    <>
      {/* <List> */}
      <ListItem
        sx={{
          cursor: isBtn && 'pointer',
          '&:hover': {
            backgroundColor:
              isBtn &&
              alpha(
                theme.palette.action.hover,
                theme.palette.action.hoverOpacity
              )
          }
        }}
        onClick={(e) => {
          if (onClick) {
            onClick(post);
          }
        }}
      >
        <ListItemIcon
          sx={{
            width: 'fit-content',
            height: 90,
            alignItems: 'center',
            maxWidth: 100
          }}
        >
          <QuizOutlined
            sx={{
              fill: lighten(theme.palette.info.light, 0.5)
            }}
            fontSize="large"
          />
        </ListItemIcon>
        <ListItemText
          primary={post.knowledgeTest.process_name}
          secondary={`המבדק מכיל 
                          ${post.knowledgeTest?.pages?.length || 1} 
                        עמודים וסך של
                         ${post.knowledgeTest?.pages.reduce(
                           (acc, curr) => acc + curr.elements.length,
                           0
                         )} 
                         שאלות`}
        />
      </ListItem>
      {/* </List> */}
      {shouldSeeLabels && (
        <Stack
          direction="row"
          gap={1}
          alignItems="center"
          sx={{ color: theme.palette.primary.main }}
        >
          <Typography>
            {`בחרת ב: 
                  ${post.labels ? post.labels.length : 0}
                  תפקידים ו- 
                  ${post.allowed_employees ? post.allowed_employees.length : 0} 
                  עובדים`}
          </Typography>
        </Stack>
      )}
      {showDivider && <Divider />}
    </>
  );
};

KnowledgeTestPreviewNameAndContentListItem.propTypes = {
  post: object,
  showDivider: bool,
  isBtn: bool,
  onClick: func
};

export default KnowledgeTestPreviewNameAndContentListItem;
