const getOrgSpecificDetails = (employee_work_places, selectedOrg) => {
  if (employee_work_places) {
    const indexOfOrg = employee_work_places.findIndex(
      (el) => el.org_ref === selectedOrg
    );
    if (indexOfOrg > -1) {
      const { org_specific_details } = employee_work_places[indexOfOrg];
      return org_specific_details || {};
    }
  }
};

export default getOrgSpecificDetails;
