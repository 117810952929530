import { Card, Divider, Grid, Stack, Typography } from '@mui/material';
import { alpha } from '@mui/material/styles';
import { isUndefined } from 'lodash';
import PropTypes from 'prop-types';
import theme from 'src/theme';

const BooleanTypeQuestion = ({
  question,
  setEvalValues,
  questionIndex,
  pageIndex,
  evalValues,
  isCorrectAnswersModeActive,
  handleMarkAnswerAsCorrect,
  correctAnswers
}) => {
  BooleanTypeQuestion.propTypes = {
    question: PropTypes.object,
    evalValues: PropTypes.object,
    questionIndex: PropTypes.number,
    pageIndex: PropTypes.number,
    setEvalValues: PropTypes.func,
    isCorrectAnswersModeActive: PropTypes.bool,
    handleMarkAnswerAsCorrect: PropTypes.func,
    correctAnswers: PropTypes.any
  };

  const handleSaveBooleanQuestionLabelChange = (newLabel, labelName) => {
    const tempVals = { ...evalValues };
    const tempQ = {
      ...question
    };
    tempQ[labelName] = newLabel;
    tempVals.pages[pageIndex].elements[questionIndex] = { ...tempQ };

    setEvalValues(tempVals);
  };

  return (
    <Grid sx={{ display: 'flex', alignItems: 'center' }} container spacing={2}>
      <Grid item xs={12} sm="auto">
        <Card
          sx={{
            borderRadius: '3rem',
            minWidth: '145px',
            width: 'fit-content',
            height: '48px',
            px: 1,
            display: 'flex',
            justifyContent: 'center',
            outline:
              isCorrectAnswersModeActive &&
              `1px dashed ${theme.palette.success.light}`,
            backgroundImage:
              !isUndefined(correctAnswers) &&
              `linear-gradient(to ${correctAnswers ? 'right' : 'left'}, ${alpha(
                theme.palette.success.light,
                0.3
              )} 50%, rgba(0,0,0,0) 0%)`,
            transition: 'all 0.3s linear'
          }}
          elevation={isCorrectAnswersModeActive ? 4 : 2}
          key={`boolean-card-page${pageIndex}-${questionIndex}`}
        >
          <Stack
            direction="row"
            gap={1}
            sx={{
              justifyContent: 'space-around',
              alignItems: 'center',
              width: '100%'
            }}
          >
            <Typography
              className="contentEditable"
              contentEditable={!isCorrectAnswersModeActive}
              suppressContentEditableWarning
              id={`page${pageIndex}-boolean${questionIndex}-labelTrue`}
              sx={{
                height: '100%',
                textAlign: 'center',
                lineHeight: '48px',
                width: '100%',
                cursor: isCorrectAnswersModeActive && 'pointer'
              }}
              onBlur={() => {
                if (!isCorrectAnswersModeActive) {
                  const elContent = document.getElementById(
                    `page${pageIndex}-boolean${questionIndex}-labelTrue`
                  ).textContent;
                  handleSaveBooleanQuestionLabelChange(elContent, 'labelTrue');
                }
              }}
              onClick={() => {
                if (isCorrectAnswersModeActive) {
                  handleMarkAnswerAsCorrect(null, true);
                }
              }}
            >
              {question.labelTrue || 'כן'}
            </Typography>
            {isCorrectAnswersModeActive && (
              <Divider orientation="vertical" variant="middle" flexItem />
            )}
            <Typography
              className="contentEditable"
              contentEditable={!isCorrectAnswersModeActive}
              suppressContentEditableWarning
              id={`page${pageIndex}-boolean${questionIndex}-labelFalse`}
              sx={{
                height: '100%',
                textAlign: 'center',
                lineHeight: '48px',
                width: '100%',
                cursor: isCorrectAnswersModeActive && 'pointer'
              }}
              onBlur={() => {
                if (!isCorrectAnswersModeActive) {
                  const elContent = document.getElementById(
                    `page${pageIndex}-boolean${questionIndex}-labelFalse`
                  ).textContent;
                  handleSaveBooleanQuestionLabelChange(elContent, 'labelFalse');
                }
              }}
              onClick={() => {
                if (isCorrectAnswersModeActive) {
                  handleMarkAnswerAsCorrect(null, false);
                }
              }}
            >
              {question.labelFalse || 'לא'}
            </Typography>
          </Stack>
        </Card>
      </Grid>
    </Grid>
  );
};

export default BooleanTypeQuestion;
