import { collection, getDocs } from '@firebase/firestore';
import { PropTypes } from 'prop-types';
import { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { db } from 'src/_firebase/firebase';
import getOrgRef from 'src/utils/getOrgRef';
import { useSelectedOrg } from '../useSelectedOrg/useSelectedOrg';
import { useCurrentUserContext } from '../useUserContext/UserContext';

const EvaluationProcessesContext = createContext();

export const EvaluationProcessesProvider = ({ children }) => {
  EvaluationProcessesProvider.propTypes = {
    children: PropTypes.any
  };
  const { currentUser } = useCurrentUserContext();
  const { selectedOrg } = useSelectedOrg();
  const [evaluationProcesses, setEvaluationProcesses] = useState();

  const memoEvaluationProcesses = useMemo(
    () => ({ evaluationProcesses, setEvaluationProcesses }),
    [evaluationProcesses, setEvaluationProcesses]
  );

  const handleRetrieveEvaluationProcesses = async () => {
    const org_ref = getOrgRef(currentUser, selectedOrg);
    // console.log(org_ref);
    const evalsRef = collection(db, `orgs/${org_ref}/evaluations`);
    await getDocs(evalsRef)
      .then((evaluation) => {
        if (evaluation.empty) {
          setEvaluationProcesses([]);
        } else {
          const temp = [];
          evaluation.docs.forEach((evaluationDoc) => {
            temp.push({
              ...evaluationDoc.data(),
              evaluation_id: evaluationDoc.id
            });
          });
          setEvaluationProcesses(temp);
          // setTicketToExamine(temp[0]);
        }
      })
      .catch((err) => {
        console.log(err);
        setEvaluationProcesses([]);
      });
  };

  useEffect(() => {
    if (currentUser && !evaluationProcesses) {
      const { type } = currentUser;
      // console.log(type);
      if (type === 'org') {
        handleRetrieveEvaluationProcesses();
      } else {
        if (selectedOrg) {
          handleRetrieveEvaluationProcesses();
        }
      }
    }
  }, [currentUser, evaluationProcesses, selectedOrg]);
  return (
    <EvaluationProcessesContext.Provider value={memoEvaluationProcesses}>
      {children}
    </EvaluationProcessesContext.Provider>
  );
};
export const useEvaluationProcesses = () =>
  useContext(EvaluationProcessesContext);
