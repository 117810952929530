import { NoteAddOutlined } from '@mui/icons-material';
import { Button, Card, Grid } from '@mui/material';
import PropTypes from 'prop-types';
import './evaluation.scss';

const QuestionsPageLayout = ({ children, handleAddNewPage, pageIndex }) => (
  <Grid
    data-collection={pageIndex}
    className="questionsPageLayout"
    item
    xs={12}
    container
    spacing={2}
  >
    <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
      <Card sx={{ width: '100%', mx: 'auto' }} className="page-card">
        {children}
      </Card>
    </Grid>
    <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
      <Button
        fullWidth
        sx={{ maxWidth: 200 }}
        variant="outlined"
        endIcon={<NoteAddOutlined />}
        color="warning"
        onClick={() => handleAddNewPage(pageIndex)}
      >
        הוספת עמוד
      </Button>
    </Grid>
  </Grid>
);
QuestionsPageLayout.propTypes = {
  children: PropTypes.any.isRequired,
  handleAddNewPage: PropTypes.func,
  pageIndex: PropTypes.number
};

export default QuestionsPageLayout;
