import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  getDocs,
  serverTimestamp,
  updateDoc
} from '@firebase/firestore';
import { db } from 'src/_firebase/firebase';

const saveKnowledgeTest = async (testDetails, org_ref, created_by) => {
  try {
    const knowledgeTestsCollection = collection(
      db,
      `orgs/${org_ref}/knowledgeTests`
    );
    const {
      knowledgeTest: { pages, ...restOfKnowledgeTest },
      knowledge_test_id,
      ...testMeta
    } = testDetails;

    let knowledgeTestRef;

    if (!knowledge_test_id) {
      // Create a new test
      knowledgeTestRef = await addDoc(knowledgeTestsCollection, {
        ...testMeta,
        created_by,
        author_ref: created_by,
        created_at: serverTimestamp(),
        org_ref,
        knowledgeTest: restOfKnowledgeTest
      });
    } else {
      // Update an existing test
      knowledgeTestRef = doc(
        db,
        `orgs/${org_ref}/knowledgeTests/${knowledge_test_id}`
      );
      await updateDoc(knowledgeTestRef, {
        ...testMeta,
        updated_by: created_by,
        updated_at: serverTimestamp()
      });
    }

    // Handle pages subcollection
    const pagesCollection = collection(knowledgeTestRef, 'pages');

    // Fetch existing pages for comparison
    const existingPagesSnapshot = await getDocs(pagesCollection);
    const existingPages = existingPagesSnapshot.docs.map((pageDoc) => ({
      id: pageDoc.id,
      ...pageDoc.data()
    }));

    // Track page IDs to determine additions and removals
    const existingPageIds = new Set(existingPages.map((page) => page.id));
    const newPageIds = new Set(pages.map((page) => page.id));

    // Add or update pages
    const pagePromises = pages.map(async (page, index) => {
      if (page.id && existingPageIds.has(page.id)) {
        // Update existing page
        const pageRef = doc(db, `${pagesCollection.path}/${page.id}`);
        return updateDoc(pageRef, {
          ...page,
          order: index,
          updated_at: serverTimestamp()
        });
      }
      // Add new page
      return addDoc(pagesCollection, {
        ...page,
        order: index,
        created_at: serverTimestamp()
      });
    });

    // Remove deleted pages
    const deletePromises = existingPages
      .filter((page) => !newPageIds.has(page.id))
      .map((page) => deleteDoc(doc(db, `${pagesCollection.path}/${page.id}`)));

    // Wait for all operations to complete
    await Promise.all([...pagePromises, ...deletePromises]);

    console.log('Knowledge test and pages saved successfully');
    return {
      state: 'success',
      testDetails: {
        ...testDetails,
        knowledge_test_id: knowledgeTestRef.id,
        post_id: knowledgeTestRef.id
      },
      post_id: knowledgeTestRef.id,
      org_ref,
      type: 'knowledge_tests'
    };
  } catch (error) {
    console.error('Error saving knowledge test:', error);
    return {
      state: 'error',
      err: error,
      testDetails,
      type: 'knowledge_tests'
    };
  }
};

export default saveKnowledgeTest;
