import { CloseOutlined } from '@mui/icons-material';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Stack,
  Typography
} from '@mui/material';
import { any, array, func } from 'prop-types';
import AddingNewEvaluationProcess from 'src/components/EvaluationProcess/AddingNewEvaluationProcess';

const BuildKnowledgeTestDialog = ({ open, onClose, handleSaveTest, posts }) => {
  BuildKnowledgeTestDialog.propTypes = {
    open: any,
    onClose: func,
    handleSaveTest: func,
    posts: array
  };
  return (
    <Dialog maxWidth="lg" fullWidth open={Boolean(open)}>
      <DialogTitle
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center'
        }}
      >
        <Stack gap={0.5} fontSize="inherit">
          <Typography fontSize="inherit" variant="inherit">
            בניית מבדק ידע
          </Typography>
          {/* <Typography variant="body1" color={theme.palette.error.dark}>
            בזמן בניית שאלון ומצרפים אותו לפרסום, אוטומטית הפרסום יסווג כמבדק
            ידע ויתווסף לכרטיסיה של מבדקי ידע
          </Typography> */}
        </Stack>
        <IconButton onClick={onClose}>
          <CloseOutlined />
        </IconButton>
      </DialogTitle>
      <Divider />
      <DialogContent>
        <AddingNewEvaluationProcess
          posts={posts}
          handleSaveDetails={handleSaveTest}
          handleGoBack={onClose}
          isEditting={open}
          type="knowledgeTest"
          componentName="knowledge_tests"
        />
      </DialogContent>
    </Dialog>
  );
};

export default BuildKnowledgeTestDialog;
