const getScoreOfQuestion = (scoringMethod, process) => {
  const numOfRequired = process.pages.map((page) =>
    page.elements.reduce(
      (acc, curr) => {
        if (scoringMethod === 'onlyRequired') {
          if (curr.isRequired) {
            // console.log(acc.required);
            return {
              ...acc,
              all: acc.all + 1,
              required: acc.required + 1
            };
          }
          return { ...acc, all: acc.all + 1 };
        }
        return { ...acc, all: acc.all + 1, required: 0 };
      },
      { all: 0, required: 0 }
    )
  );
  const [{ all, required }] = numOfRequired;

  const getScoreOfThisQuestion = (question) => {
    if (scoringMethod === 'onlyRequired') {
      return question.isRequired ? all / required : 0;
    }
    return all / all;
  };
  // const withScores = process.pages.map((page) => ({...page, elements: page.elements.map((question) => ({...question, score: s}))}))
  const processWithScores = process.pages.map((page) => ({
    ...page,
    elements: page.elements.map((question) => ({
      ...question,
      score: getScoreOfThisQuestion(question)
    }))
  }));
  return { ...process, pages: processWithScores };
};

export default getScoreOfQuestion;
